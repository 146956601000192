<template>
    <lassoLayout>
        <div class="logout-page">
            <div class="logout-content">
                <v-container>
                    <div class="logout-block">
                        <h1 class="logout-title">
                            Goodbye!
                        </h1>
                        <div class="logout-description">
                            You've successfully logged out. Come back soon!
                        </div>
                        <div class="logout-spaceship"></div>
                        <div class="logout-actions">
                            <router-link :to="{name: 'explore'}" class="tetriatary_btn medium" v-ripple>
                                <span>Home</span>
                            </router-link>
                            <button type="button" @click="login" class="c2a_btn medium" v-ripple>
                                Log In
                            </button>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from '@/layouts/lassoLayout.vue';
import {mapGetters} from "vuex";

export default {
    name: 'logged-out',
    components: {
        lassoLayout
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            authData: 'AUTH_DATA',
        }),
    },
    mounted() {
        if (this.authData.accessToken) {
            this.$router.push({name: 'explore'});
        }
    },
    methods: {
        login() {
            this.$router.push({name: 'login'});
        }
    }
}
</script>

<style lang="scss">
.fizz-effect {
    @include button-fizz-effect;
}
</style>
